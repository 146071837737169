import "./DownloadByDate.css";
import { useState } from "react";
import { CircularProgress, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";
import domtoimage from "dom-to-image-chrome-fix-retina";
import JSZip, { forEach } from "jszip";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
// Components
import StandardPageWrapper from "../../components/StandardPageWrapper/StandardPageWrapper";
import StandardButton from "../../components/StandardButton/StandardButton";
import StandardPreviewCardDownload from "../../components/StandardPreviewCardDownload/StandardPreviewCardDownload";
// ICONs & SVGs
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import CustomMuiSelect from "../../components/CustomMuiSelect/CustomMuiSelect";
import { SelectedElement } from "rsuite/esm/Picker";
import Checkbox from "@mui/material/Checkbox";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import StandardOutlinedTextfield from "../../components/StandardOutlinedTextfield/StandardOutlinedTextfield";


import ListItemText from "@mui/material/ListItemText";

function DownloadByDate() {
  const { enqueueSnackbar } = useSnackbar();

  const [allIDCards, setAllIDCards] = useState([]);
  const [resAllIDCards, setResAllIDCards] = useState([]);

  const [selectedYear, setSelectedYear] = useState(
    new Date().toLocaleDateString("en-CA")
  );

  const [searchLoading, setSearchLoading] = useState(false);

  const [downloadAllBySchoolLoading, setDownloadAllBySchoolLoading] =
    useState(false);
  const [downloadAllLoading, setDownloadAllLoading] = useState(false);

  const [selectedSchool, setSelectedSchool] = useState([]);

  const [totalCount, setTotalCount] = useState(0);

  const [open, setOpen] = useState(false);
  const [openCountModal, setOpenCountModal] = useState(false);

  const [startCount, setStartCount] = useState({oneSideFront:1,bothSideFront:1,bothSideBack:1});

  const onDownloadAllBySchool = async () => {
    const zip = new JSZip();

    setDownloadAllBySchoolLoading(true);

    for (let i = 0; i < allIDCards.length; i++) {
      const oneSide = zip.folder(`${allIDCards[i]?.school_name}/one-side`);
      const bothSideFront = zip.folder(
        `${allIDCards[i]?.school_name}/both-side/front`
      );
      const bothSideBack = zip.folder(
        `${allIDCards[i]?.school_name}/both-side/back`
      );

      const oneSideCardArr = allIDCards?.[i]?.details.filter(
        (c) => c?.id_card?.type == 1
      );
      const twoSideCardArr = allIDCards?.[i]?.details.filter(
        (c) => c?.id_card?.type == 2
      );

      let oneSideCount = 1;
      for (let i = 0; i < oneSideCardArr.length; i++) {
        for (let j = 0; j < oneSideCardArr[i]?.user_data.length; j++) {
          var frontNode = document.getElementById(
            `previewcustomCard-front-${oneSideCardArr[i]?.user_data[j]?.mapped_id}`
          );

          await domtoimage
            .toPng(frontNode, { cacheBust: true })
            .then(function (dataUrl) {
              var img = new Image();
              img.src = dataUrl; // base64 img
              const b64data = dataUrl.split(",")[1];

              oneSide.file(`${oneSideCount}.png`, b64data, { base64: true });
            })
            .catch(function (error) {
              console.error("Error Downloading Image!", error);
              setDownloadAllBySchoolLoading(false);
            });

          oneSideCount++;
        }
      }

      let bothSideCount = 1;
      for (let i = 0; i < twoSideCardArr.length; i++) {
        for (let j = 0; j < twoSideCardArr[i]?.user_data.length; j++) {
          var frontNode = document.getElementById(
            `previewcustomCard-front-${twoSideCardArr[i]?.user_data[j]?.mapped_id}`
          );
          var backNode = document.getElementById(
            `previewcustomCard-back-${twoSideCardArr[i]?.user_data[j]?.mapped_id}`
          );

          await domtoimage
            .toPng(frontNode, { cacheBust: true })
            .then(function (dataUrl) {
              var img = new Image();
              img.src = dataUrl; // base64 img
              const b64data = dataUrl.split(",")[1];

              bothSideFront.file(`${bothSideCount}.png`, b64data, {
                base64: true,
              });
            })
            .catch(function (error) {
              console.error("Error Downloading Image!", error);
              setDownloadAllBySchoolLoading(false);
            });

          await domtoimage
            .toPng(backNode, { cacheBust: true })
            .then(function (dataUrl) {
              var img = new Image();
              img.src = dataUrl; // base64 img
              const b64data = dataUrl.split(",")[1];

              bothSideBack.file(`${bothSideCount}.png`, b64data, {
                base64: true,
              });
            })
            .catch(function (error) {
              console.error("Error Downloading Image!", error);
              setDownloadAllBySchoolLoading(false);
            });

          bothSideCount++;
        }
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "ID SOLUTIONS SCHOOLS ID.zip");
      setDownloadAllBySchoolLoading(false);
    });
  };

  const onDownloadAllClick = async () => {
    const zip = new JSZip();

    const oneSide = zip.folder("one-side");
    const bothSideFront = zip.folder("both-side/front");
    const bothSideBack = zip.folder("both-side/back");

    setDownloadAllLoading(true);
    var bothSideFrontCount;
    var bothSideBackCount;
    var oneSideCount;

    for (let i = 0; i < allIDCards.length; i++) {
  
      const oneSideCardArr = allIDCards?.[i]?.details.filter(
        (c) => c?.id_card?.type == 1
      );
      const twoSideCardArr = allIDCards?.[i]?.details.filter(
        (c) => c?.id_card?.type == 2
      ); 
        if (i === 0) {
          oneSideCount = startCount.oneSideFront;
        }
      for (let i = 0; i < oneSideCardArr.length; i++) {
        for (let j = 0; j < oneSideCardArr[i]?.user_data.length; j++) {
          var frontNode = document.getElementById(
            `previewcustomCard-front-${oneSideCardArr[i]?.user_data[j]?.mapped_id}`
          );

          await domtoimage
            .toPng(frontNode, { cacheBust: true })
            .then(function (dataUrl) {
              var img = new Image();
              img.src = dataUrl; // base64 img
              const b64data = dataUrl.split(",")[1];

              oneSide.file(`${oneSideCount}.png`, b64data, {
                base64: true,
              });
            })
            .catch(function (error) {
              console.error("Error Downloading Image!", error);
              setDownloadAllLoading(false);
            });

          oneSideCount++;
        }
      }
      if (i===0){
      bothSideFrontCount = startCount.bothSideFront;
      bothSideBackCount = startCount.bothSideBack;
      }
      for (let i = 0; i < twoSideCardArr.length; i++) {
        for (let j = 0; j < twoSideCardArr[i]?.user_data.length; j++) {
          var frontNode = document.getElementById(
            `previewcustomCard-front-${twoSideCardArr[i]?.user_data[j]?.mapped_id}`
          );
          var backNode = document.getElementById(
            `previewcustomCard-back-${twoSideCardArr[i]?.user_data[j]?.mapped_id}`
          );

          await domtoimage
            .toPng(frontNode, {
              cacheBust: true,
            })
            .then(function (dataUrl) {
              var img = new Image();
              img.src = dataUrl; // base64 img
              const b64data = dataUrl.split(",")[1];

              bothSideFront.file(`${bothSideFrontCount}.png`, b64data, {
                base64: true,
              });
              bothSideFrontCount++;
            })
            .catch(function (error) {
              console.error("Error Downloading Image!", error);
              setDownloadAllLoading(false);
            });

          await domtoimage
            .toPng(backNode, { cacheBust: true })
            .then(function (dataUrl) {
              var img = new Image();
              img.src = dataUrl; // base64 img
              const b64data = dataUrl.split(",")[1];

              bothSideBack.file(`${bothSideBackCount}.png`, b64data, {
                base64: true,
              });
              bothSideBackCount++;
            })
            .catch(function (error) {
              console.error("Error Downloading Image!", error);
              setDownloadAllLoading(false);
            });
        }
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "ID SOLUTIONS.zip");
      setDownloadAllLoading(false);
    });
  };

  const onDownloadExcel = async () => {
    const excelHeaders = [
      "School Name",
      "Front Side",
      "Both Side",
      "Contact Person Name",
      "Delivery Address",
      "Contact Person Mobile No",
      "Total Count",
    ];

    let worksheetDataObj = [];
    resAllIDCards?.forEach((idCard) => {
      const oneSideCardArr = idCard?.details.filter(
        (c) => c?.id_card?.type == 1
      );
      const twoSideCardArr = idCard?.details.filter(
        (c) => c?.id_card?.type == 2
      );

      let onesideCount = 0,
        twosideCount = 0;

      oneSideCardArr?.forEach(
        (user) => (onesideCount += user?.user_data?.length)
      );
      twoSideCardArr?.forEach(
        (user) => (twosideCount += user?.user_data?.length)
      );

      worksheetDataObj = [
        ...worksheetDataObj,
        {
          "School Name": idCard?.school_name,
          "Front Side": onesideCount || 0,
          "Both Side": twosideCount || 0,
          "Contact Person Name": idCard?.contat_person_name || "",
          "Delivery Address": idCard?.delivery_address || "",
          "Contact Person Mobile No": idCard?.contact_person_number || "",
          "Total Count": idCard?.verified_count || "",
        },
      ];
    });

    var worksheet = XLSX.utils.json_to_sheet(worksheetDataObj, {
      header: excelHeaders,
    });
    const wb = {
      Sheets: { "ID SOLUTIONS ID-CARD": worksheet },
      SheetNames: ["ID SOLUTIONS ID-CARD"],
    };
    XLSX.writeFile(wb, "ID SOLUTIONS.xls");
  };

  const onFilterSearch = async () => {
    setSearchLoading(true);

    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/filterbydate`, {
        frommonthYear: selectedYear,
      })
      .then((res) => {
        if (res.data.success) {
          setResAllIDCards(res.data.data);
          setSelectedSchool([]);
          setAllIDCards([]);
          setStartCount({ oneSideFront: 1, bothSideFront: 1, bothSideBack: 1 });
          const totalCount = Object.values(res.data.data).reduce(
            (sum, key) => sum + (key?.details[0]?.user_data?.length || 0),
            0
          );
          setTotalCount(totalCount);
        } else {
          enqueueSnackbar(res.data?.data || "Error Searching, Try Again !!!", {
            variant: "error",
          });
        }
        setSearchLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSearchLoading(false);
        return enqueueSnackbar("Something Went Wrong !!!", {
          variant: "error",
        });
      });
  };

const onSelectSchool = (event) => {
  const selectedSchool = event.target.value;

  // setSelectedSchool(selectedSchool);
  setAllIDCards([]);

  if (selectedSchool.includes("All")) {
    setAllIDCards(resAllIDCards);
    setOpen(false);
  } else {
    const filteredIDCards = resAllIDCards.filter((obj) =>
      selectedSchool.includes(obj.school_name)
    );
    setSelectedSchool(
      typeof selectedSchool === "string"
        ? selectedSchool.split(",")
        : selectedSchool
    );
    setAllIDCards(filteredIDCards);
  }
};



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
      setOpenCountModal(false);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <StandardPageWrapper
      header="Download By Date"
      btns={
        <>
          <input
            type="date"
            style={{ marginRight: 15 }}
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          />

          <StandardButton
            color="white"
            variant="contained"
            style={{
              background: "darkgreen !important",
              marginRight: "15px !important",
            }}
            onClick={onFilterSearch}
            label={<SearchIcon />}
            loading={searchLoading}
          />

          <StandardButton
            color="white"
            variant="contained"
            style={{
              background: "darkgreen !important",
              marginRight: "15px !important",
            }}
            onClick={handleClickOpen}
            label={"Select School"}
            loading={searchLoading}
          >
            {" "}
            Select School
          </StandardButton>

          {/* {allIDCards?.length > 0 && selectedSchool === "All" && (
              <StandardButton
                variant="contained"
                onClick={onDownloadAllBySchool}
                loading={downloadAllBySchoolLoading}
                style={{ background: "darkgreen !important" }}
                label="By School"
                startIcon={<DownloadIcon />}
              />
            )} */}

          {allIDCards?.length > 0 && (
            <StandardButton
              variant="contained"
              onClick={() => setOpenCountModal(true)}
              loading={downloadAllLoading}
              style={{ background: "darkgreen !important" }}
              label="All"
              startIcon={<DownloadIcon />}
            />
          )}

          {allIDCards?.length > 0 && (
            <StandardButton
              variant="contained"
              onClick={onDownloadExcel}
              startIcon={<DownloadIcon />}
              style={{ background: "darkgreen !important" }}
              label="Excel"
            />
          )}
        </>
      }
    >
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth="true">
        <DialogTitle>Select School</DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: "100%", overflowX: "hidden" }} fullwidth>
            <InputLabel id="demo-multiple-name-label">Select School</InputLabel>
            <Select
              name="select"
              multiple
              value={selectedSchool}
              onChange={onSelectSchool}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {/* {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={personName.indexOf(option.value) > -1}
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))} */}

              {resAllIDCards?.map((c, i) => (
                <MenuItem value={c?.school_name} key={i}>
                  <Checkbox
                    checked={selectedSchool.indexOf(c?.school_name) > -1}
                  />
                  <ListItemText
                    primary={`${c?.school_name} (${c?.details[0]?.user_data?.length})`}
                  />
                </MenuItem>
              ))}
              <MenuItem value={"All"}>
                {/* <Checkbox /> */}
                <ListItemText primary={`All (${totalCount})`} />
              </MenuItem>
            </Select>
          </FormControl>
          {/* <CustomMuiSelect
                label="Select School"
                val={selectedSchool}
                onChange={(e) => onSelectSchool(e.target.value)}
                width={150}
                size="small"
                disabled={searchLoading}
                style={{ margin: "10px !important" }}
              >
                {resAllIDCards?.map((c, i) => (
                  <MenuItem value={c?.school_name} key={i}>
                    {`${c?.school_name} (${c?.details[0]?.user_data?.length})`}
                  </MenuItem>
                ))}
                <MenuItem value="All" key="All">
                  <Checkbox />
                  {`All (${totalCount})`}
                </MenuItem>
              </CustomMuiSelect> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCountModal}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle>Enter Download Count</DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: "100%", overflowX: "hidden" }} fullwidth>
            <StandardOutlinedTextfield
              label="One Side Start Count"
              name="oneSideFront"
              val={startCount?.oneSideFront}
              setVal={setStartCount}
              spreadData={startCount}
              default={1}
            />
            <StandardOutlinedTextfield
              label="Both Side Start Front Count"
              name="bothSideFront"
              val={startCount?.bothSideFront}
              setVal={setStartCount}
              spreadData={startCount}
            />
            <StandardOutlinedTextfield
              label="Both Side Back Front Count"
              name="bothSideBack"
              val={startCount?.bothSideBack}
              setVal={setStartCount}
              spreadData={startCount}
            />
            {/* <StandardButton
              color="white"
              variant="contained"
              style={{
                background: "darkgreen !important",
                marginRight: "15px !important",
              }}
              onClick={onDownloadAllClick}
              label={"Start Download"}
              loading={searchLoading}
            /> */}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <StandardButton
            variant="contained"
            onClick={onDownloadAllClick}
            loading={downloadAllLoading}
            // style={{ background: "darkgreen !important" }}
            label="Start Download"
            startIcon={<DownloadIcon />}
          />
        </DialogActions>
      </Dialog>

      <div id="preview">
        <div className="previewCard_cont">
          {searchLoading ? (
            <CircularProgress
              size={30}
              sx={{ display: "block", margin: "auto", color: "#4b49ac" }}
            />
          ) : (
            <>
              {allIDCards?.map((c) => (
                <div key={c?.school_id}>
                  <h3 className="previewCard_cont_schoolHeader">
                    {c?.school_name}
                  </h3>
                  {c?.details?.map((detail) =>
                    detail?.user_data?.map((d, j) => (
                      <StandardPreviewCardDownload
                        key={detail?.user_data[j]?.mapped_id}
                        custom_uid={detail?.user_data[j]?.mapped_id}
                        cardData={detail?.id_card}
                        userData={detail?.user_data[j]}
                      />
                    ))
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </StandardPageWrapper>
  );
}

export default DownloadByDate;
