import './DownloadByClass.css';
import { useState, useEffect } from 'react';
import { CircularProgress, MenuItem } from '@mui/material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import JSZip, { forEach } from 'jszip';
import { saveAs } from 'file-saver';
import domtoimage from "dom-to-image-chrome-fix-retina";
// Components
import StandardPageWrapper from '../../components/StandardPageWrapper/StandardPageWrapper';
import CustomMuiSelect from '../../components/CustomMuiSelect/CustomMuiSelect';
import StandardButton from '../../components/StandardButton/StandardButton';
import StandardPreviewCardDownload from '../../components/StandardPreviewCardDownload/StandardPreviewCardDownload';
// ICONs & SVGs
import SearchIcon from '@mui/icons-material/Search';

function DownloadByClass() {
  const { enqueueSnackbar } = useSnackbar();

  const [allIDCards, setAllIDCards] = useState([]);

  const [allBtnData, setAllBtnData] = useState({ school: '', class: '', is_verified: '' });
  const [date, setDate] = useState('');
  const [allSchoolData, setAllSchoolData] = useState([]);
  const [allCLassData, setAllCLassData] = useState([]);

  const [allSchoolDataLoading, setAllSchoolDataLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);

  // ----------------------
  const [downloadAllLoading, setDownloadAllLoading] = useState(false);

  useEffect(() => {
    fetchAllSchools();
  }, [])

  const fetchAllSchools = async () => {
    await axios.get(`${process.env.REACT_APP_BASE_URL}/user/getallschool`)
      .then(res => {
        if (res.data.success) {
          setAllSchoolData(res.data.data)
        } else {
          enqueueSnackbar('Error Fetching Schools, Try Again !!!', { variant: 'error' })
        }
        setAllSchoolDataLoading(false);
      })
      .catch(err => {
        console.log(err);
        setAllSchoolDataLoading(false);
        return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
      })
  }

  const fetchAllClass = async (e) => {
    setAllBtnData({ ...allBtnData, school: e.target.value, class: '', is_verified: '' })
    setDate(null);
    await axios.post(`${process.env.REACT_APP_BASE_URL}/user/getclassesbyschoolid`, { schoolid: e.target.value })
      .then(res => {
        if (res.data.success) {
          setAllCLassData(res.data.data)
        } else {
          enqueueSnackbar('Error Fetching Class, Try Again !!!', { variant: 'error' })
        }
      })
      .catch(err => {
        console.log(err);
        return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
      })
  }
  const fetchAsIsVerified = async (e) => {
    setAllBtnData({ ...allBtnData, is_verified: e.target.value })
  }

  const onSearchByClass = async () => {
    if (Object.values(allBtnData).includes('')) return enqueueSnackbar('Please Select All The Values !!!', { variant: 'error' })

    setSearchLoading(true);
    await axios.post(`${process.env.REACT_APP_BASE_URL}/user/getmapped_data_byclassid`, { class_id: allBtnData.class })
      .then(res => {
        if (res.data.success) {
          console.log(res.data.data)
          console.log(date);
          let formatedDate;
          if(date === null || date === '')
          {
            formatedDate=null;
            // formatedDate=date.format().slice(0,10);
          }
          else{
            formatedDate=date;
          }
          console.log(formatedDate);
          if (allBtnData.is_verified === '1') {
            let updatedData = [];
            for (let index = 0; index < res.data.data[0].user_data.length; index++) {
              const element = res.data.data[0].user_data[index];
              if (element.is_verified === 1) {
                if(formatedDate !== null)
                {
                    if(formatedDate===element.updated_at.slice(0,10))
                    {
                      updatedData.push(element);
                    }
                }
                else
                {
                  updatedData.push(element);
                }
              }
            }
            console.log(updatedData.length);
            res.data.data[0].user_data = updatedData;
            console.log(res.data.data)
            setAllIDCards(res.data.data)
          }
          else if (allBtnData.is_verified === '0') {
            let updatedData = [];
            for (let index = 0; index < res.data.data[0].user_data.length; index++) {
              const element = res.data.data[0].user_data[index];
              if (element.is_verified === 0) {
                if(formatedDate !== null)
                {
                    if(formatedDate===element.updated_at.slice(0,10))
                    {
                      updatedData.push(element);
                    }
                }
                else
                {
                  updatedData.push(element);
                }
              }
            }
            console.log(updatedData.length);
            res.data.data[0].user_data = updatedData;
            console.log(res.data.data)
            setAllIDCards(res.data.data)
          }
          else {
            let updatedData = [];
            for (let index = 0; index < res.data.data[0].user_data.length; index++) {
              const element = res.data.data[0].user_data[index];
                if(formatedDate !== null)
                {
                    if(formatedDate===element.updated_at.slice(0,10))
                    {
                      updatedData.push(element);
                    }
                }
                else
                {
                  updatedData.push(element);
                }
            }
            console.log(updatedData.length);
            res.data.data[0].user_data = updatedData;
            console.log(res.data.data)
            setAllIDCards(res.data.data)
          }
        } else {
          setAllIDCards([])
          enqueueSnackbar(res.data.message, { variant: 'error' })
        }
        setSearchLoading(false);
      })
      .catch(err => {
        console.log(err);
        setSearchLoading(false);
        return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
      })
  }

  const onDownloadAllClick = async () => {
    const zip = new JSZip();

    setDownloadAllLoading(true);

    if (allIDCards[0]?.id_card?.type == 1) {
      const oneSide = zip.folder("one-side");

      let oneSideCount = 1;
      for (let i = 0; i < allIDCards[0]?.user_data?.length; i++) {
        var frontNode = document.getElementById(`previewcustomCard-front-${allIDCards[0]?.user_data[i]?.mapped_id}`);

        await domtoimage
          .toPng(frontNode, { cacheBust: true })
          .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl; // base64 img
            const b64data = dataUrl.split(",")[1];

            oneSide.file(`${oneSideCount}.png`, b64data, {
              base64: true,
            });
          })
          .catch(function (error) {
            console.error("Error Downloading Image!", error);
            setDownloadAllLoading(false);
          });

        oneSideCount++;
      }

    } else {
      const bothSideFront = zip.folder("both-side/front");
      const bothSideBack = zip.folder("both-side/back");

      let bothSideCount = 1;
      for (let i = 0; i < allIDCards[0]?.user_data.length; i++) {
        var frontNode = document.getElementById(`previewcustomCard-front-${allIDCards[0]?.user_data[i]?.mapped_id}`);
        var backNode = document.getElementById(`previewcustomCard-back-${allIDCards[0]?.user_data[i]?.mapped_id}`);

        await domtoimage
          .toPng(frontNode, { cacheBust: true })
          .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl; // base64 img
            const b64data = dataUrl.split(",")[1];

            bothSideFront.file(`${bothSideCount}.png`, b64data, {
              base64: true,
            });
          })
          .catch(function (error) {
            console.error("Error Downloading Image!", error);
            setDownloadAllLoading(false);
          });

        await domtoimage
          .toPng(backNode, { cacheBust: true })
          .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl; // base64 img
            const b64data = dataUrl.split(",")[1];

            bothSideBack.file(`${bothSideCount}.png`, b64data, {
              base64: true,
            });
          })
          .catch(function (error) {
            console.error("Error Downloading Image!", error);
            setDownloadAllLoading(false);
          });

        bothSideCount++;
      }

    }

    const selectedSchoolName = allSchoolData?.filter(s => s.schoolid === allBtnData.school)?.[0]?.name
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, `${selectedSchoolName} ID Cards.zip`);
      setDownloadAllLoading(false);
    });
  }

  return (
    <StandardPageWrapper
      header="Download By Class"
      btns={
        <>
          <CustomMuiSelect
            label="Select School"
            val={allBtnData.school}
            onChange={fetchAllClass}
            width={150}
            size="small"
            disabled={searchLoading}
          >
            {allSchoolData?.map((c, i) => (
              <MenuItem value={c?.schoolid} key={i}>
                {c?.name}
              </MenuItem>
            ))}
          </CustomMuiSelect>

          <CustomMuiSelect
            label="Select Class"
            val={allBtnData.class}
            onChange={(e) =>
              setAllBtnData({ ...allBtnData, class: e.target.value })
            }
            width={150}
            size="small"
            sx={{ marginRight: 1.5 }}
            disabled={searchLoading}
          >
            {allCLassData?.map((c, i) => (
              <MenuItem
                value={c?.classid}
                key={i}
              >{`${c?.standard} - ${c?.division}`}</MenuItem>
            ))}
          </CustomMuiSelect>
          <CustomMuiSelect
            label="Is Verified"
            val={allBtnData.is_verified}
            onChange={fetchAsIsVerified}
            // onChange={(e) =>
            // setAllBtnData({ ...allBtnData, is_verified: e.target.value })
            // }
            width={150}
            size="small"
            sx={{ marginRight: 1.5 }}
            disabled={searchLoading}
          >
            <MenuItem
              value="all"
              key="2"
            >All</MenuItem>
            <MenuItem
              value="1"
              key="1"
            >Verified</MenuItem>
            <MenuItem
              value="0"
              key="0"
            >Not Verified</MenuItem>
          </CustomMuiSelect>
          <input
            type="date"
            style={{ marginRight: 15 }}
            value={date}
            onChange={(e) => setDate(e.target.value)}
            // onChange={(newDate) => setDate(newDate)}
          />
          {/* <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ marginRight: 1.5 }}>
            <DemoContainer components={['DatePicker']}>
              <DemoItem>
                <DatePicker
                  label="Verified On"
                  value={date}
                  onChange={(newDate) => setDate(newDate)}
                  className="customLabel"
                  classes={{ label: 'customLabel' }}
                  sx={{
                    marginRight: 1.5,
                    backgroundColor: "white",
                    width: "160px"
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider> */}
          <StandardButton
            color="white"
            variant="contained"
            style={{ background: "darkgreen !important" }}
            onClick={onSearchByClass}
            label={<SearchIcon />}
            loading={searchLoading}
          />

          {allIDCards?.length > 0 && (
            <StandardButton
              variant="contained"
              onClick={onDownloadAllClick}
              loading={downloadAllLoading}
              style={{ background: "darkgreen !important" }}
              label="Download All"
            />
          )}
        </>
      }
    >
      <div id="preview">
        <div className="previewCard_cont">
          {searchLoading ? (
            <CircularProgress
              size={30}
              sx={{ display: "block", margin: "auto", color: "#4b49ac" }}
            />
          ) : (
            <>
              {allIDCards?.[0]?.user_data?.map((u, i) => (
                // <StandardPreviewCard key={i} index={i} cardData={c?.id_card} userData={c?.user_data[0]} />
                <StandardPreviewCardDownload
                  key={u?.mapped_id}
                  custom_uid={u?.mapped_id}
                  cardData={allIDCards?.[0]?.id_card}
                  userData={u}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </StandardPageWrapper>
  );
}

export default DownloadByClass
