import './Schools.css'
import { useEffect, useState } from 'react'
import { Button, CircularProgress, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import * as XLSX from 'xlsx';
// Components
import StandardPageWrapper from '../../../components/StandardPageWrapper/StandardPageWrapper'
import StandardOutlinedTextfield from '../../../components/StandardOutlinedTextfield/StandardOutlinedTextfield'
import ReactCustomTable from '../../../components/ReactCustomTable/ReactCustomTable'
import StandardMuiDialogWrapper from '../../../components/StandardMuiDialogWrapper/StandardMuiDialogWrapper'
// ICONs & SVGs
import { Delete, Edit, ArrowForward, Download } from '@mui/icons-material'

function Schools() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [allSchoolData, setAllSchoolData] = useState([]);
    const [allSchoolDataLoading, setAllSchoolDataLoading] = useState(true);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogBtnLoading, setDialogBtnLoading] = useState(false);
    const [addSchoolData, setAddSchoolData] = useState({ name: '', address: '', school_login_id: '', secret_code: '', contact_person: '', del_address: '', con_mobile: '' });
    // Delete
    const [deleteID, setDeleteID] = useState('');
    const [deleteLoading, setDeleteLoading] = useState(false);
    // Edit
    const [editActive, setEditActive] = useState(false);
    const [editId, setEditId] = useState('');
    // Download
    const [downloadId, setDownloadId] = useState('');
    const [downloadLoading, setDownloadLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");


    useEffect(() => {
        fetchAllSchools();
    }, [])

    const fetchAllSchools = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}/user/getallschool`)
            .then(res => {
                if (res.data.success) {
                    setAllSchoolData(res.data.data);
                } else {
                    enqueueSnackbar('Error Fetching Schools, Try Again !!!', { variant: 'error' })
                }
                setAllSchoolDataLoading(false);
            })
            .catch(err => {
                console.log(err);
                setAllSchoolDataLoading(false);
                return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
            })
    }

    const onExited = () => {
        setAddSchoolData({ name: '', address: '', school_login_id: '', secret_code: '', contact_person: '', del_address: '', con_mobile: '' });
        setEditActive(false);
    }

    const onAddSchool = async () => {
        if (Object.values(addSchoolData)?.includes('')) return enqueueSnackbar('Please Fill All The Details !!!', { variant: 'error' })
        setDialogBtnLoading(true);

        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/addschool`, addSchoolData)
            .then(res => {
                if (res.data.success) {
                    setAllSchoolData([...allSchoolData, res.data.result[0]])
                    enqueueSnackbar('School Added Successfully !!!', { variant: 'success' })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setOpenDialog(false);
                setDialogBtnLoading(false);
            })
            .catch(err => {
                console.log(err);
                setDialogBtnLoading(false);
                return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
            })
    }

    const onDelete = async (e, data) => {
        e.stopPropagation();
        if (!data.schoolid) return enqueueSnackbar('School ID Not Found !!!', { variant: 'error' })
        setDeleteID(data.schoolid);
        setDeleteLoading(true);

        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/deleteschool`, { schoolid: data.schoolid })
            .then(res => {
                if (res.data.success) {
                    setAllSchoolData(allSchoolData?.filter(s => s?.schoolid != data?.schoolid))
                    enqueueSnackbar('School Deleted Successfully !!!', { variant: 'success' })
                } else {
                    enqueueSnackbar('Error Deleting School, Try Again !!!', { variant: 'error' })
                }
                setDeleteID('');
                setDeleteLoading(false);
            })
            .catch(err => {
                console.log(err);
                setDeleteLoading(false);
                setDeleteID('');
                return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
            })
    }

    const onEdit = (e, data) => {
        e.stopPropagation();
        if (!data.schoolid) return enqueueSnackbar('School ID Not Found !!!', { variant: 'error' })
        setEditId(data.schoolid);
        setOpenDialog(true);
        setEditActive(true);
        setAddSchoolData({
            name: data?.name, address: data?.address, school_login_id: data?.school_login_id, secret_code: data?.secret_code,
            contact_person: data?.contact_person, del_address: data?.del_address, con_mobile: data?.con_mobile
        })
    }

    const onSaveEdit = async () => {
        if (!editId) return enqueueSnackbar('School ID Not Found !!!', { variant: 'error' })
        if (Object.values(addSchoolData)?.includes('')) return enqueueSnackbar('Please Fill All The Details !!!', { variant: 'error' })

        setDialogBtnLoading(true);

        let body = { ...addSchoolData, schoolid: editId }
        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/updateschool`, body)
            .then(res => {
                if (res.data.success) {
                    const editDataIndex = allSchoolData.findIndex(i => i.schoolid == editId);
                    if (editDataIndex === -1) return enqueueSnackbar('Error Updating School !!!', { variant: 'success' })

                    let arr = [...allSchoolData]
                    arr[editDataIndex] = body;
                    setAllSchoolData(arr)
                    enqueueSnackbar('School Updated Successfully !!!', { variant: 'success' })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setOpenDialog(false);
                setEditActive(false);
                setDialogBtnLoading(false);
                setEditId('');
                setAddSchoolData({ name: '', address: '', school_login_id: '', secret_code: '', contact_person: '', del_address: '', con_mobile: '' });
            })
            .catch(err => {
                console.log(err);
                setDialogBtnLoading(false);
                return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
            })
    }

    const onDownloadClick = async (e, data) => {
        e.stopPropagation();
        if (downloadLoading) return;
        if (!data?.verified_count || data?.verified_count <= 0) return enqueueSnackbar('No ID Cards Mapped To This School !!!', { variant: 'error', preventDuplicate: false })
        if (!data?.schoolid) return enqueueSnackbar('School ID Not Found !!!', { variant: 'error' })
        setDownloadId(data?.schoolid);
        setDownloadLoading(true);

        let body = { id: data?.schoolid }
        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/schoolexportexcel`, body)
            .then(res => {
                if (res.data.success) {
                    if (res.data.data?.length <= 0) return enqueueSnackbar('No Data Found', { variant: 'error' })

                    // const excelHeaders = Object.keys(res.data.data?.[0])

                    var fixedHeaders = [
                      "first_name_student",
                      "phone_no_student",
                      "class_id_student",
                      "school_id_student",
                      "Photo",
                      "class_id"
                    ];

                    var dynamicHeaders = Object.keys(res.data.data?.[0]).filter(
                      (header) => !fixedHeaders.includes(header)
                    );

                    var allHeaders = fixedHeaders.concat(dynamicHeaders);

                    var worksheet = XLSX.utils.json_to_sheet(res.data.data, {
                      header: allHeaders,
                    });
                    const wb = { Sheets: { 'ID SOLUTIONS Schools': worksheet }, SheetNames: ['ID SOLUTIONS Schools'] };
                    XLSX.writeFile(wb, 'ID SOLUTIONS.xls');
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setDownloadLoading(false);
            })
            .catch(err => {
                console.log(err);
                setDownloadLoading(false);
                return enqueueSnackbar('Something Went Wrong Downloading !!!', { variant: 'error' })
            })
    }

    const onNavigatetoClass = (e, data) => navigate(`/schools/${data.schoolid}`, { state: data })

    const tableActions = [
        { icon: <Delete sx={{ cursor: 'pointer' }} color='error' />, onClick: onDelete, btnType: 'del' },
        { icon: <Edit sx={{ cursor: 'pointer', color: '#7978e9' }} />, onClick: onEdit },
        { icon: <Download sx={{ cursor: 'pointer', color: 'darkgreen' }} />, onClick: onDownloadClick, btnType: 'download' },
        { icon: <ArrowForward sx={{ cursor: 'pointer' }} color='primary' />, onClick: onNavigatetoClass },
    ]

       const filterIdCards = (data) => {
         return data.filter((card) => {
           const searchString =
             `${card.schoolid} ${card.name} ${card.school_login_id} ${card.contact_person} ${card.address}`.toLowerCase();
           return searchString.includes(searchInput.toLowerCase());
         });
       };
    return (
      <StandardPageWrapper
        header="Schools"
        btns={
          <>
            <Button
              variant="contained"
              component="label"
              onClick={() => setOpenDialog(true)}
            >
              Add School
            </Button>
          </>
        }
      >
        <TextField
          sx={{ marginRight: "30px", marginLeft: "10px" }}
          variant="outlined"
          type="text"
          fullWidth
          placeholder="Search Schools..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <div id="schools">
          <ReactCustomTable
            data={filterIdCards(allSchoolData)}
            tableColumns={tableColumns}
            loading={allSchoolDataLoading}
            tableActions={tableActions}
            actionsColumnWidth={140}
            deleteID={deleteID}
            deleteLoading={deleteLoading}
            rowDataDeleteKey="schoolid"
            circularColor="#d32f2f"
            downloadId={downloadId}
            downloadLoading={downloadLoading}
          />
        </div>

        <StandardMuiDialogWrapper
          open={openDialog}
          setOpen={setOpenDialog}
          onExited={onExited}
          dialogTitle={`${editActive ? "Update" : "Add"} Schools`}
          onSaveBtnClick={editActive ? onSaveEdit : onAddSchool}
          saveBtnLabel={editActive ? "Update" : "Save"}
          saveBtnLoading={dialogBtnLoading}
        >
          <StandardOutlinedTextfield
            label="School Name"
            name="name"
            val={addSchoolData.name}
            setVal={setAddSchoolData}
            spreadData={addSchoolData}
          />
          <StandardOutlinedTextfield
            label="School Address"
            name="address"
            val={addSchoolData.address}
            setVal={setAddSchoolData}
            spreadData={addSchoolData}
          />
          <StandardOutlinedTextfield
            label="School Login ID"
            name="school_login_id"
            val={addSchoolData.school_login_id}
            setVal={setAddSchoolData}
            spreadData={addSchoolData}
          />
          <StandardOutlinedTextfield
            label="School Password"
            name="secret_code"
            val={addSchoolData.secret_code}
            setVal={setAddSchoolData}
            spreadData={addSchoolData}
          />
          <StandardOutlinedTextfield
            label="Contact Person Name"
            name="contact_person"
            val={addSchoolData.contact_person}
            setVal={setAddSchoolData}
            spreadData={addSchoolData}
          />
          <StandardOutlinedTextfield
            label="Delivery Address"
            name="del_address"
            val={addSchoolData.del_address}
            setVal={setAddSchoolData}
            spreadData={addSchoolData}
          />
          <StandardOutlinedTextfield
            label="Contact Person Mobile No"
            name="con_mobile"
            val={addSchoolData.con_mobile}
            setVal={setAddSchoolData}
            spreadData={addSchoolData}
          />
        </StandardMuiDialogWrapper>
      </StandardPageWrapper>
    );
}

export default Schools

const tableColumns = [
    {
        label: 'School ID',
        key: 'schoolid',
        width: 100,
        align: 'center'
    },
    {
        label: 'Name',
        key: 'name',
        width: 250,
        fixed: false,
    },
    {
        label: 'Login ID',
        key: 'school_login_id',
        width: 150,
        fixed: false,
    },

    {
        label: 'Password',
        key: 'school_secret',
        width: 150,
        fixed: false,
    },
    {
        label: 'Total Count',
        key: 'verified_count',
        width: 100,
        align: 'center'
    },
    {
        label: 'Address',
        key: 'address',
        flexGrow: 1,
        fullText: true,
        minWidth: 200,
    },
    {
        label: 'Contact Person Name',
        key: 'contact_person',
        width: 200,
        fixed: false,
        fullText: true,
    },
    {
        label: 'Delivery Address',
        key: 'del_address',
        width: 150,
        fixed: false,
        fullText: true,
    },
    {
        label: 'Contact Person Mobile No',
        key: 'con_mobile',
        width: 150,
        fixed: false,
        fullText: true,
    }
]