import './User.css';
import { useEffect, useState } from 'react';
import { Breadcrumbs, Button, MenuItem, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
// Components
import StandardPageWrapper from '../../../components/StandardPageWrapper/StandardPageWrapper';
import ReactCustomTable from '../../../components/ReactCustomTable/ReactCustomTable';
import StandardMuiDialogWrapper from '../../../components/StandardMuiDialogWrapper/StandardMuiDialogWrapper';
import StandardOutlinedTextfield from '../../../components/StandardOutlinedTextfield/StandardOutlinedTextfield';
// ICONs & SVGs
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Delete, Edit } from '@mui/icons-material'

function User() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [addUserData, setAddUserData] = useState({ first_name: '', phone_no: '' })
    const [allUserData, setAllUserData] = useState([]);
    const [allUserDataLoading, setAllUserDataLoading] = useState(true);
    // Dialog
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogBtnLoading, setDialogBtnLoading] = useState(false);
    // Delete
    const [deleteID, setDeleteID] = useState('');
    const [deleteLoading, setDeleteLoading] = useState(false);
    // Edit
    const [editActive, setEditActive] = useState(false);
    const [editData, setEditData] = useState({});

    useEffect(() => {
        if (!state) return navigate(-1);

        fetchUserByClass();
    }, [])

    const fetchUserByClass = async () => {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/getuserbyclassid`, { classid: state.classid })
            .then(res => {
                if (res.data.success) {
                    setAllUserData(res.data.data)
                } else {
                    enqueueSnackbar('Error Fetching Class, Try Again !!!', { variant: 'error' })
                }
                setAllUserDataLoading(false);
            })
            .catch(err => {
                console.log(err);
                setAllUserDataLoading(false);
                return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
            })
    }

    const onEdit = (e, data) => {
        e.stopPropagation();
        setOpenDialog(true);
        setEditActive(true);
        setAddUserData({ first_name: data?.first_name, phone_no: data?.phone_no })
        setEditData(data);
    }

    const onAddUser = async () => {
        if (Object.values(addUserData)?.includes('')) return enqueueSnackbar('Please Fill All The Details !!!', { variant: 'error' })

        const body = { ...addUserData, role: 'student', classid: state.classid, schoolid: state.schoolid, is_verified: 1 }
        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/signup`, body)
            .then(res => {
                if (res.data.success) {
                    setAllUserData([...allUserData, res.data.data[0]])
                    enqueueSnackbar('User Added Successfully !!!', { variant: 'success' })
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' })
                }
                setOpenDialog(false);
            })
            .catch(err => {
                console.log(err);
                return enqueueSnackbar('Something Went Wrong !!!', { variant: 'error' })
            })
    }

    const onExited = () => {
        setAddUserData({ first_name: '', phone_no: '' })
        setEditData({})
    }

    const onSaveEdit = async (status) => {
        if (Object.values(addUserData)?.includes("")) return enqueueSnackbar('Please Fill All The Details !!!', { variant: 'error' });
        if (Object.keys(editData)?.length <= 0) return enqueueSnackbar('Please Try Again !!!', { variant: 'error' });

        setDialogBtnLoading(true);

        const body = {
            user_id: editData?.id,
            first_name: addUserData?.first_name,
            last_name: editData?.last_name,
            phone_no: addUserData?.phone_no,
            email: editData?.email,
            password: editData?.password,
            role: editData?.role,
            classid: editData?.classid,
            schoolid: editData?.schoolid,
            verification_status: 1
        }

        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/updateuser`, body)
            .then(res => {
                if (res.data.success) {
                    let arr = [...allUserData]
                    let changeIndex = arr.findIndex(a => a.id == editData?.id)
                    if (changeIndex !== -1) {
                        arr[changeIndex] = { ...arr[changeIndex], ...body }
                        setAllUserData(arr);
                    }

                    setOpenDialog(false)
                    enqueueSnackbar('User Updated Successfully !!!', { variant: 'success' });
                } else {
                    enqueueSnackbar(res.data.message, { variant: 'error' });
                }
                setDialogBtnLoading(false)
            })
            .catch(err => {
                console.log(err);
                enqueueSnackbar('Error, Please Try Again !!!', { variant: 'error' });
                setOpenDialog(false);
                setDialogBtnLoading(false);
            })
    }

    const onDelete = async (e, data) => {
        e.stopPropagation();
        setDeleteID(data.id);
        setDeleteLoading(true);

        await axios.post(`${process.env.REACT_APP_BASE_URL}/user/deleteuser`, { user_id: data.id })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    setAllUserData(allUserData?.filter((s) => s?.id != data?.id));
                    enqueueSnackbar("User Deleted Successfully !!!", { variant: "success" });
                } else {
                    enqueueSnackbar("Error Deleting User, Try Again !!!", { variant: "error" });
                }
                setDeleteID("");
                setDeleteLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setDeleteLoading(false);
                setDeleteID("");
                return enqueueSnackbar("Something Went Wrong !!!", { variant: "error" });
            });

    }
    const onAddClass = () => { }

    const tableActions = [
        { icon: <Delete sx={{ cursor: 'pointer' }} color='error' />, onClick: onDelete, btnType: 'del' },
        { icon: <Edit sx={{ cursor: 'pointer', color: '#7978e9' }} />, onClick: onEdit },
    ]
    return (
        <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ marginBottom: 1 }} >
                <Typography color="grey">Schools</Typography>
                <Typography color="grey">Class</Typography>
                <Typography sx={{ color: '#7978e9', fontWeight: 'bold' }} >User</Typography>
            </Breadcrumbs>
            <StandardPageWrapper
                // header={`Class of : ${state?.name}`}
                header='User'
                btns={<>
                    <Button variant="contained" component="label" onClick={() => setOpenDialog(true)}>
                        Add Student
                    </Button>
                </>}
            >

                <div id='schoolpage_user'>
                    <ReactCustomTable data={allUserData} tableColumns={tableColumns} loading={allUserDataLoading}
                        tableActions={tableActions} actionsColumnWidth={90} deleteID={deleteID} deleteLoading={deleteLoading}
                        rowDataDeleteKey='classid' circularColor='#d32f2f'
                    />
                </div>

                {/* ADD-UPDATE Dialog  */}
                <StandardMuiDialogWrapper open={openDialog} setOpen={setOpenDialog} onExited={onExited}
                    dialogTitle={`${editActive ? 'Update' : 'Add'} User`} onSaveBtnClick={editActive ? onSaveEdit : onAddUser}
                    saveBtnLabel={editActive ? 'Update' : 'Save'} saveBtnLoading={dialogBtnLoading}
                >
                    <StandardOutlinedTextfield label="First Name" name='first_name' val={addUserData.first_name} setVal={setAddUserData} spreadData={addUserData} />
                    <StandardOutlinedTextfield label="Phone Number" name='phone_no' type='number' val={addUserData.phone_no} setVal={setAddUserData} spreadData={addUserData} />
                </StandardMuiDialogWrapper>
            </StandardPageWrapper>
        </>
    )
}

export default User

const tableColumns = [
    {
        label: 'School ID',
        key: 'schoolid',
        width: 75,
        fixed: true,
        align: 'center',
    },
    {
        label: 'Class ID',
        key: 'classid',
        width: 75,
        fixed: true,
        align: 'center',
    },
    {
        label: 'User ID',
        key: 'id',
        width: 75,
        fixed: true,
        align: 'center',
    },
    {
        label: 'First Name',
        key: 'first_name',
        minWidth: 150,
        flexGrow: 1,
        fullText: true,
    },
    // {
    //     label: 'Last Name',
    //     key: 'last_name',
    //     minWidth: 150,
    //     flexGrow: 1,
    //     fullText: true,
    // },
    {
        label: 'Role',
        key: 'role',
        width: 100,
        fullText: true,
    },
    {
        label: 'Phone Number',
        key: 'phone_no',
        width: 150,
    }
]